import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'
import styled from 'styled-components';

const Wrapper = styled.div`
  padding: 1rem;
  @media (min-width: 768px) {
    font-size: 24px;
  }
  @media (min-width: 1440px) {
    font-size: 36px;
  }
  font-family: 'Source Sans Pro', 'Helvetica Neue', Arial, sans-serif;
  font-size: 14px;
`

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <Wrapper>
      <h1>Pagina non trovata</h1>
      <p>Torna alla <a href="/">homepage</a></p>
    </Wrapper>
  </Layout>
)

export default NotFoundPage
